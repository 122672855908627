<template class="mr-3">
  <div class="card">
    <a-skeleton v-if="loading" :active="true" :paragraph="{ rows: 4 }" />

    <div v-else class="card-body">
      <div class="d-flex mt-2 my-2">
        <div class="text-danger">
          <a-icon type="tag" />
          Les activités supprimées
        </div>
        <div class="ml-2">
          <a-icon type="tag" />
          Les activités actives
        </div>
      </div>
      <a-input-search
        :placeholder="$t('inscription.chercherParActivite')"
        @keyup="(e) => onSearch(e.target.value)"
      />
      <br />

      <a-directory-tree default-expand-all @select="onSelect">
        <a-tree-node
          v-for="(service, service_id) in services"
          :key="service_id"
          :title="service"
          v-if="
            activitiesCache[service_id] &&
            activitiesCache[service_id].length > 0
          "
        >
          <a-icon slot="icon" type="tags" />
          <a-tree-node
            v-for="activity in activitiesCache[service_id]"
            :key="activity._id"
            :title="activity.designation"
            is-leaf
          >
            <a-icon slot="icon" type="tag" v-if="activity.status == 'active'" />
            <a-icon slot="icon" type="tag" class="text-danger" v-else />
          </a-tree-node>
        </a-tree-node>
      </a-directory-tree>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import router from "@/router";
import apiClient from "@/services/axios";
export default {
  data() {
    return {
      activitiessData: {},
      activitiesCache: {},
      services: {},
      loading: true,
    };
  },
  beforeCreate() {
    apiClient
      .post("/services/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => {
        let services = Object();
        res.data.forEach((element) => {
          services[element._id] = element.name;
        });
        this.services = services;
      })

      .catch((e) => this.$message.error(this.$t("error.aucService")));
    apiClient
      .post("/activities/filter", {
        query: {},
        aggregation: {
          $project: {
            _id: 1,
            service: 1,
            designation: 1,
            status: 1,
          },
        },
      })
      .then((res) => {
        const { data } = res;
        this.activitiesList = data;
        this.activitiessData = _.mapValues(
          _.groupBy(data, "service"),
          (clist) => clist.map((elem) => _.omit(elem, "service"))
        );
        this.activitiesCache = { ...this.activitiessData };
      })
      .catch((e) => this.$message.error(this.$t("error.aucActivite")))
      .finally(() => (this.loading = false));
  },
  methods: {
    onSelect(keys, event) {
      const key = keys[0];
      for (const activitylist in this.activitiessData) {
        this.activitiessData[activitylist].forEach((ac) => {
          if (key == ac._id) router.push(`/PaiementActivite/${key}`);
        });
      }
    },
    onSearch(v) {
      if (v) {
        for (let [key, value] of Object.entries(this.activitiessData)) {
          this.activitiesCache[key.toString()] = value.filter((elem) =>
            elem.designation.toLowerCase().includes(v.toLowerCase())
          );
        }
        console.log(this.activitiesCache);
      } else {
        this.activitiesCache = { ...this.activitiessData };
      }
    },
  },
};
</script>
<style scoped>
.card >>> .ant-tree {
  font-size: 17px;
}
.card >>> .ant-tree-title {
  font-weight: 500;
}
</style>
